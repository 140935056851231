import React, { useState } from 'react'; //useCallback
import Content from 'views/components/ui/content';
import { useMutation } from 'react-apollo';
import { UPLOAD_BLOCK_TEMPLATE } from 'api/graphql/v2/mutations/BlockTemplate';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import Papa from 'papaparse';

const UploadContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 600px;
  margin: 0 auto;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  display: inline-block;
`;

const FileName = styled.p`
  margin: 8px 0;
`;

const allowedFields = [
  'BT_Email',
  'Regular_Shift_Days',
  'Regular_Shift_Timing',
  'Optional_Shift_Timing',
  'Axon_Clinic_ID',
  'Axon_Location_Code',
  'Axon_Slot_Type'
];

const BlockTemplateConfig: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState<any[]>([]);

  const [uploadBlockTemplate] = useMutation(UPLOAD_BLOCK_TEMPLATE, {
    onCompleted: () => {
      setSuccess('Block template configuration uploaded successfully!');
      setFile(null);
      console.log('parsedData data', parsedData);
      setParsedData([]);
    },
    onError: err => {
      setError(err.message);
    }
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (selectedFile.type !== 'text/csv') {
        setError('Please upload a CSV file');
        return;
      }
      setFile(selectedFile);
      setError(null);
      setSuccess(null);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file before uploading.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async results => {
        console.log('Parsed CSV Data:', results.data);

        // Preprocess the data to include only allowed fields
        const filteredData = results.data.map((row: any) => {
          const filteredRow: any = {};
          for (const key of allowedFields) {
            if (row.hasOwnProperty(key)) {
              filteredRow[key] = row[key];
            }
          }
          return filteredRow;
        });

        setParsedData(filteredData);

        try {
          await uploadBlockTemplate({ variables: { data: filteredData } });
          setSuccess('Upload successful!');
          setFile(null);
        } catch (err) {
          console.error('Upload error:', err);
          setError('Upload failed!');
        } finally {
          setLoading(false);
        }
      },
      error: err => {
        setError('Error parsing CSV file: ' + err.message);
        setLoading(false); // Stop loading on error
      }
    });
  };

  // const downloadTemplate = () => {
  //   const template = `BT_Name,BT_Email,Regular_Shift_Days,Regular,Regular_Shift_Timing,Optional_Shift,Optional_Shift_Timing,Axon_Clinic_Name,Axon_Clinic_ID,Axon_Location,Axon_Location_Code,Axon_Slot_Type,Date_Added,Effective_Date,Supervisor,EID\nYaling Yang,yyang@corticatest.onmicrosoft.com,"M,T,W,R,F","Full Time A - CA, MA",09:00-13:30;15:00-17:30,"Saturday - CA, MA",09:00-12:00,"Carlsbad, CA",2,Both,7,Care Sessions,2/10/2025,2/10/2025,,\nHolly Frolek,hfrolek@corticatest.onmicrosoft.com,"M,W,F","Full Time B - CA, MA",08:30-11:30;13:00-15:00;15:30-17:30,,#N/A,"Burlington, MA",15,Both,7,Care Sessions,2/5/2025,2/3/2025,,`;
  //   const blob = new Blob([template], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'block_template_sample.csv';
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // };

  return (
    <Content fullHeight={true} loading={loading} data={[0]}>
      {() => (
        <>
          <UploadContainer>
            {/* <Button onClick={downloadTemplate} variant="secondary">
              Download Sample Template
            </Button> */}

            <FileLabel htmlFor="block-template-file">
              <FileInput
                id="block-template-file"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
              <Button variant="secondary" as="span">
                Select CSV
              </Button>
            </FileLabel>

            {file && (
              <>
                <FileName>Selected file: {file.name}</FileName>
                <Button
                  onClick={handleUpload}
                  disabled={loading} // Disable button while loading
                  variant="primary"
                >
                  {loading ? 'Uploading...' : 'Upload'}
                </Button>
              </>
            )}

            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}
          </UploadContainer>
        </>
      )}
    </Content>
  );
};

export default BlockTemplateConfig;
