import { IConfigurations } from 'model/configurations';

export const DT_APPT_TYPE_TITLE = 'DT Appointment Type';
export const ABA_APPT_TYPE_TITLE = 'ABA Appointment Type';
export const ABA_APPT_SUBTYPE_TITLE = 'ABA Appointment SubType';
export const DT_APPT_SUBTYPE_TITLE = 'DT Appointment SubType';
export const SPECIALITIES_TITLE = 'Specialities';
export const AVAILABILITY_TEMPLATE = 'Availabilty Templates';
export const MED_APPT_TYPE_TITLE = 'MED Appointment Type';
export const MED_APPT_SUBTYPE_TITLE = 'MED Appointment SubType';
export const BLOCK_TEMPLATE = 'Block Templates';
export const configurations: IConfigurations[] = [
  {
    id: 'ABAAppointmentType',
    title: ABA_APPT_TYPE_TITLE
  },
  {
    id: 'DTAppointmentType',
    title: DT_APPT_TYPE_TITLE
  },
  {
    id: 'MEDAppointmentType',
    title: MED_APPT_TYPE_TITLE
  },
  {
    id: 'ABAAppointmentSubType',
    title: ABA_APPT_SUBTYPE_TITLE
  },
  {
    id: 'DTAppointmentSubType',
    title: DT_APPT_SUBTYPE_TITLE
  },
  {
    id: 'MEDAppointmentSubType',
    title: MED_APPT_SUBTYPE_TITLE
  },
  {
    id: 'Specialities',
    title: SPECIALITIES_TITLE
  },
  {
    id: 'availabilityTemplates',
    title: AVAILABILITY_TEMPLATE
  },
  {
    id: 'blockTemplates',
    title: BLOCK_TEMPLATE
  }
];
