import { useQuery } from '@apollo/react-hooks';
import { EditAvailabilityWrap } from 'views/components/clients/profile/availability/EditAvailability.style';
import AddAvailabilityTemplate from 'pages/ConfigurationsPage/addAvailabilityTemplate';
import { GET_PROVIDER_WITH_AVAILABILITY } from 'api/graphql/v2/queries/AvailabilityTemplate';
import Content from 'views/components/ui/content';
import {
  generateDefaultTemplate,
  mapDayAvailabilityToCalendarEvent,
  MapEventsToDayAvailability
} from 'utils/mappers/availabilityTemplate';
import { IClinic } from 'model/v2/clinic';
import {
  AvailabilityTemplateData,
  ModalData,
  ProviderDailyMaxHours
} from 'model/v2/availabilityTemplate';
import { IProviderSpecialty } from 'model/v2/providerSpecialty';
import { useMemo } from 'react';
import { getProviderDefaultClinics } from 'utils/mappers/availabilityTemplate';
import { TEMPLATE_DEFAULT_OPTION } from 'components/AvailabilityTemplateModalForm/UpperModal';
import { Appointment_Types } from 'components/AvailabilityTemplateModalForm/EditForm/dropdownOptions';

import { useReactiveVar } from '@apollo/client';
import { mainCalendarPrefDayVar } from 'utils/cache/calendar';
interface ProviderWithAvailabilityAndClinic {
  providerWithAvailability: ProviderData;
  clinicWithItsHub: IClinic[];
  providerClinic: {
    clinic: IClinic;
  };
}
interface ProviderData {
  id: Number;
  name: string;
  employment: string;
  isDefaultTemplateInherited: boolean;
  speciality: IProviderSpecialty;
  dailyMaxHours?: ProviderDailyMaxHours[];
  availability: AvailabilityTemplateData;
}

const EditWorkingHoursV2 = ({ match }: any) => {
  const staffId = match?.params?.id;
  const calendarDate = useReactiveVar(mainCalendarPrefDayVar);

  const { data, loading, error } = useQuery<ProviderWithAvailabilityAndClinic>(
    GET_PROVIDER_WITH_AVAILABILITY,
    {
      variables: {
        id: Number(staffId),
        date: calendarDate
      },
      fetchPolicy: 'network-only'
    }
  );

  const providerDefaultClinicsIds = useMemo(() => {
    return getProviderDefaultClinics(
      data?.clinicWithItsHub,
      data?.providerClinic.clinic
    );
  }, [data]);

  const activeTemplateName = useMemo(() => {
    return data?.providerWithAvailability.availability?.name;
  }, [data]);

  const events = useMemo(
    () =>
      mapDayAvailabilityToCalendarEvent(
        data?.providerWithAvailability?.availability?.dayAvailabilities || [],
        providerDefaultClinicsIds,
        Appointment_Types,
        calendarDate
      ),
    [data, providerDefaultClinicsIds]
  );
  const submittedData: ModalData = useMemo(() => {
    return {
      department: data?.providerWithAvailability?.speciality?.department || '',
      speciality: data?.providerWithAvailability?.speciality?.id!,
      specialityAbr:
        data?.providerWithAvailability?.speciality?.abbreviation || '',
      templateType:
        data?.providerWithAvailability?.availability?.id ||
        TEMPLATE_DEFAULT_OPTION.value,
      templateName:
        data?.providerWithAvailability?.availability?.name ||
        TEMPLATE_DEFAULT_OPTION.label,
      Day: MapEventsToDayAvailability(events),
      clinics: data?.clinicWithItsHub || [],
      providerDefaultClinicsIds: providerDefaultClinicsIds,
      dailyMaxHours: data?.providerWithAvailability?.dailyMaxHours,
      employment: data?.providerWithAvailability.employment
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, events]);

  if (
    submittedData.templateType === TEMPLATE_DEFAULT_OPTION.value &&
    events.length === 0
  ) {
    generateDefaultTemplate(submittedData);
  }

  return (
    <Content data={data} error={error} loading={loading}>
      {() => (
        <div className="popup-wrap">
          <EditAvailabilityWrap>
            <AddAvailabilityTemplate
              isAbaProvider={
                data?.providerWithAvailability?.speciality?.department === 'ABA'
              }
              templateName={activeTemplateName}
              selectedDate={calendarDate}
              Data={submittedData!}
              calenderEvents={events}
              providerId={Number(staffId)}
              providerTemplateId={
                data?.providerWithAvailability.isDefaultTemplateInherited
                  ? undefined
                  : data?.providerWithAvailability.availability?.id
              }
              openModalForm={
                data?.providerWithAvailability.isDefaultTemplateInherited
              }
              isProviderAvailabilityMode={true}
              providerName={data?.providerWithAvailability.name}
              providerTz={data?.providerClinic.clinic.timezone}
            />
          </EditAvailabilityWrap>
        </div>
      )}
    </Content>
  );
};

export default EditWorkingHoursV2;
