import moment from 'moment';
import { IFormEvent, ISmartForm } from 'model/v2';

export const mapABASmartResultGeneration = (
  data: ISmartForm,
  initEvent?: IFormEvent
) => ({
  clientId: data.client?.id,
  appointmentTypeId: data.appType,
  startDate: moment(
    data.startDate ? data.startDate : initEvent?.startDate
  ).format('YYYY-MM-DD'),
  endDate: moment(data.endDate).format('YYYY-MM-DD'),
  sessionDuration: {
    min: data.aba?.sessionDuration.min || 0,
    max: data.aba?.sessionDuration.max || 0
  },
  timezone: data.client?.clinic?.timezone,
  isIgnoreClientProfileAvailability: data.isIgnoreClientProfileAvailability,
  providerAvailabilityFilter: {
    preferredProviders: [...(data.providerFilter?.preferredProviders || [])],
    preferredSpecialties: [...(data.providerFilter?.preferredSpecialties || [])]
  },
  clientAvailabilityFilter: data?.clientAvailabilityDayFilter
    ? {
        clientAvailabilityDayFilter: data.clientAvailabilityDayFilter
      }
    : undefined
});

export const mapDTSmartResultGeneration = (
  data: ISmartForm,
  initEvent?: IFormEvent
) => ({
  clientId: data.client?.id,
  appointmentTypeId: data.appType,
  startDate: moment(
    data.startDate ? data.startDate : initEvent?.startDate
  ).format('YYYY-MM-DD'),
  endDate: moment(data.endDate).format('YYYY-MM-DD'),
  sessionDuration: data.dt?.sessionDuration,
  timezone: data.client?.clinic?.timezone,
  isIgnoreClientProfileAvailability: data.isIgnoreClientProfileAvailability,
  providerAvailabilityFilter: {
    preferredProviders: [...(data.providerFilter?.preferredProviders || [])],
    preferredSpecialties: [...(data.providerFilter?.preferredSpecialties || [])]
  },
  clientAvailabilityFilter: data?.clientAvailabilityDayFilter
    ? {
        clientAvailabilityDayFilter: data.clientAvailabilityDayFilter
      }
    : undefined
});

export const _isWelcomeVisit = (apptType: number | undefined) => {
  const env = process.env.REACT_APP_STAGE;
  let isWelcomeVisit = false;
  if (env === 'DEV' && apptType === 165) isWelcomeVisit = true;
  else if (env === 'STAGING' && apptType === 202) isWelcomeVisit = true;
  else if (env === 'UAT' && apptType === 183) isWelcomeVisit = true;
  else if (env === 'PROD' && apptType === 249) isWelcomeVisit = true;
  return isWelcomeVisit;
};

export const mapSingleApptlSmartResultGeneration = (data: ISmartForm) => {
  const isAba = Boolean(data?.aba);
  const defaultLocationFilter = {
    isInClinic: true,
    isOffsite: isAba,
    isTelehealth: true
  };
  const someLocationSelected = Boolean(
    data.locationFilter &&
      (data.locationFilter.isInClinic ||
        data.locationFilter.isOffsite ||
        data.locationFilter.isTelehealth)
  );
  const locationFilter = someLocationSelected
    ? data.locationFilter!
    : defaultLocationFilter;
  const clinicFilter = {
    clinicIds: data?.clinicFilter?.clinicIds || [],
    applyClinicFilterToTelehealth: true
  };
  const isWelcomeVisit = _isWelcomeVisit(data.appType);

  if (isWelcomeVisit) {
    locationFilter.isTelehealth = true;
    locationFilter.isInClinic = false;
    clinicFilter.applyClinicFilterToTelehealth = false;
  }

  let duration = undefined;
  if (isAba) {
    if (!data.aba?.singleSessionDuration)
      throw new Error(
        'Attempted to perform ABA single session search without duration'
      );
    duration = convertHourMinuteStringToMinutes(
      data.aba?.singleSessionDuration
    );
    clinicFilter.applyClinicFilterToTelehealth = true;
  }

  let startTime;
  let endTime;
  if (data.startDate === undefined) {
    startTime = moment(data.startDate).toDate();
    endTime = moment(data.startDate)
      .add(6, 'month')
      .endOf('day')
      .toDate();
  } else {
    startTime = moment(data.startDate)
      .startOf('day')
      .toDate();
    endTime = moment(data.startDate)
      .add(6, 'month')
      .endOf('day')
      .toDate();
  }
  return {
    resultLength: 10,
    clientId: data.client?.id,
    appointmentTypeId: data.appType,
    apptSubTypeId: data.appSubType,
    startTime: startTime,
    endTime: endTime,
    duration,
    isIgnoreClientProfileAvailability: data.isIgnoreClientProfileAvailability,
    clientAvailabilityFilter: data?.clientAvailabilityDayFilter
      ? {
          clientAvailabilityDayFilter: data.clientAvailabilityDayFilter
        }
      : undefined,
    providerAvailabilityFilter: {
      preferredProviders: [...(data.providerFilter?.preferredProviders || [])],
      preferredSpecialties: [
        ...(data.providerFilter?.preferredSpecialties || [])
      ]
    },
    locationFilter,
    clinicFilter,
    timezone: data.client?.clinic?.timezone,
    aggregateLikeCards: true
    // duration: data.duration // until we actually have a field within the single-event SS UI for duration, don't submit as part of request
  };
};

export const getCarePlanWeeklyHours = (value?: string | null) => {
  if (!value) return 0;
  const arr = value.split(':');
  return (
    (parseInt(arr[0]) || 0) +
    (arr.length > 1 && arr[1] ? (parseInt(arr[1]) * 1.0) / 60 : 0)
  );
};

export const convertHourMinuteStringToMinutes = (hoursAndMinutes: string) => {
  const split = hoursAndMinutes.split(':');
  if (split.length !== 2) throw new Error('Invalid hours and minutes string');
  const [hours, minutes] = split;
  const hoursNumber = Number(hours);

  if (Number.isNaN(hoursNumber))
    throw new Error('Hours portion of hours and minutes string is NaN');
  const minutesNumber = Number(minutes);
  if (Number.isNaN(minutesNumber))
    throw new Error('Minutes portion of hours and minutes string is NaN');
  return 60 * hoursNumber + minutesNumber;
};
